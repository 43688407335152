import React from "react";
import styles from "./Socials.module.scss";

const links: { name: string; link: string }[] = [
  {
    name: "Insta",
    link: "https://www.instagram.com/streetpieces/",
  },
  {
    name: "Facey",
    link: "https://www.facebook.com/streetpieces",
  },
  {
    name: "Spotify",
    link: "https://open.spotify.com/artist/6A7pzeOZUxq2EqjEoT29dn",
  },
  {
    name: "Bandcamp",
    link: "https://streetpieces.bandcamp.com/",
  },
];

export const Socials: React.FC = () => (
  <ul className={styles.root}>
    {links.map((l) => (
      <li key={l.name}>
        <a href={l.link} target="_blank" rel="noreferrer">
          {l.name}
        </a>
      </li>
    ))}
  </ul>
);
