const importAll = (r: __WebpackModuleApi.RequireContext) => r.keys().map(r)

const bigImagesContext = require.context("../../../images/gallery", false, /\.(png|jpe?g)$/)
const thumbnailContext = require.context("../../../images/gallery/thumbnails", false, /\.(png|jpe?g)$/)
const bigImages = importAll(bigImagesContext)
const thumbnails = importAll(thumbnailContext)

export const photos: {thumbnail: string, fullSize: string }[] = bigImages.map(
  // @ts-ignore
  (img, index) => ({thumbnail: thumbnails[index].default, fullSize: img.default})
)
