import React from 'react';
import styles from './App.module.scss';
import {Shell} from "./components/shell/Shell";

const App:React.FC = () => (
    <div className={styles.app}>
      <Shell />
    </div>
  );

export default App;
