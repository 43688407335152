import React, {useCallback, useState} from "react";
import cn from "classnames";
import styles from "./Shell.module.scss";
import {Border} from "../border/Border";
import {useToggle} from "../../hooks/use-toggle";
import {Nav} from "../routes/nav/Nav";
import {Page} from "../../domain/Page";
import {OutNow} from "../routes/out-now/OutNow";
import {Listen} from "../routes/listen/Listen";
import {Socials} from "../socials/Socials";
import {Photos} from "../routes/photos/Photos";
import {sleep} from "../../helpers/time";

export const Shell: React.FC = () => {
  const {off: closeContent, on: openContent, state: contentOpen} = useToggle(true);
  const {off: closeSides, on: openSides, state: sidesOpen} = useToggle(true);
  const [page, setPage] = useState(Page.Listen);

  const changePage = useCallback(
    async (page: Page) => {
      closeSides();
      await sleep(200)

      closeContent();
      await sleep(300);

      setPage(page);
      await sleep(500)

      openContent();
      await sleep(200);

      openSides()
    },
    [setPage, closeContent, openContent, closeSides, openSides]
  );

  return (
    <main className={styles.root}>
      <div className={styles.main}>
        <h1 className={styles.title}>Street Pieces</h1>
        <div className={styles.contentWrapper}>
          <Border sidesClosed={!sidesOpen} top/>
          <div className={cn(styles.content, {[styles.closed]: !contentOpen, [styles.shadow]: page !== Page.Photos})}>
            {/* TODO: Use router for this so that you can save a specific URL */}
            {pageToContent[page]}
            <Listen hidden={page !== Page.Listen}/>
          </div>
          <Border sidesClosed={!sidesOpen} />
        </div>
        <Nav onClick={changePage} page={page}/>
      </div>
    </main>
  );
};

const pageToContent = {
  [Page.OutNow]: <OutNow/>,
  [Page.Socials]: <Socials/>,
  [Page.Photos]: <Photos/>,
  [Page.Listen]: <></>
}
