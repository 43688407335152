import React from "react";
import cn from "classnames";
import styles from "./Listen.module.scss";

// TODO: There are issues here: 1. Can't click the whole thing 2: Stops playing on page change
export const Listen: React.FC<{ hidden: boolean }> = ({ hidden }) => (
  <iframe
    title="Spotify Player"
    className={cn(styles.player, { [styles.hidden]: hidden })}
    src="https://open.spotify.com/embed/artist/6A7pzeOZUxq2EqjEoT29dn"
    allow="encrypted-media"
  />
);
