import React from "react";
import cn from "classnames";
import styles from "./Nav.module.scss";
import { Page, pages } from "../../../domain/Page";

export const Nav: React.FC<{ onClick: (page: Page) => void; page: Page }> = ({
  onClick,
  page,
}) => (
  <nav className={styles.root}>
    {pages.map((p) => (
      <button
        key={p.title}
        className={cn({ [styles.active]: page === p.page })}
        onClick={() => onClick(p.page)}
      >
        {p.title}
      </button>
    ))}
  </nav>
);
