import React from "react";
import cn from "classnames";
import styles from "./Border.module.scss";

export const Border: React.FC<{ top?: true; sidesClosed: boolean }> = ({
  top,
  sidesClosed,
}) => (
  <div className={cn(styles.border, top ? styles.top : undefined)}>
    <div className={styles.sides}>
      <div className={cn(styles.side, { [styles.closed]: sidesClosed })} />
      <div className={cn(styles.side, { [styles.closed]: sidesClosed })} />
    </div>
    <div className={styles.topOrBottom} />
  </div>
);
