import React, {useCallback, useEffect, useRef, useState} from "react";
import cn from "classnames";
import styles from "./Photos.module.scss";
import {photos} from "./photo-list";

export const Photos: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState<number>(0);

  const onClick = useCallback((index: number) => {
    setModalOpen(true);
    setImageIndex(index)
  }, []);

  const close = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const next = useCallback(() => {
    if (imageIndex + 1 > photos.length - 1) {
      setImageIndex(0)
    } else {
      setImageIndex(imageIndex + 1)
    }
  }, [setImageIndex, imageIndex])

  const previous = useCallback(() => {
    if (imageIndex - 1 < 0) {
      setImageIndex(photos.length - 1)
    } else {
      setImageIndex(imageIndex - 1)
    }
  }, [setImageIndex, imageIndex])

  return (
    <div className={styles.root}>
      <ImageModal open={modalOpen} close={close} next={next} previous={previous} image={photos[imageIndex].fullSize}/>
      {photos.map((image, index) => (
        <div
          key={image.thumbnail}
          className={styles.thumbnail}
          style={{backgroundImage: `url(${image.thumbnail})`}}
          onClick={() => onClick(index)}
        />
      ))}
    </div>
  );
};

const ImageModal: React.FC<{
  open: boolean;
  close: () => void;
  image?: string;
  next: () => void;
  previous: () => void;
}> = ({open, close, image, next, previous}) => {
  const backdropRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    backdropRef.current?.focus();
  });

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === "Escape") {
        e.preventDefault();
        close();
      }
      if (e.key === "ArrowRight") {
        e.preventDefault();
        next();
      }
      if (e.key === "ArrowLeft") {
        e.preventDefault();
        previous();
      }
    },
    [close, next, previous]
  );

  return (
    <div
      className={cn(styles.modalWrapper, {[styles.closed]: !open})}
      onKeyDown={onKeyDown}
      ref={backdropRef}
      tabIndex={0}
    >
      {image && (
        <>
          <div className={styles.modalInner}>
            {image && <img src={image} alt="" className={styles.image}/>}
          </div>
          <button className={cn(styles.navButton, styles.closeButton)} onClick={close}>
            X
          </button>
          <button className={cn(styles.navButton, styles.backButton)} onClick={next}>
            «
          </button>
          <button className={cn(styles.navButton, styles.nextButton)} onClick={next}>
            »
          </button>
        </>
      )}
    </div>
  );
};
