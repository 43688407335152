export enum Page {
  OutNow,
  Listen,
  Socials,
  Photos,
}

type pageRef = {
  page: Page;
  title: string;
};

export const pages: pageRef[] = [
  { page: Page.OutNow, title: "Out Now" },
  { page: Page.Listen, title: "Listen" },
  { page: Page.Socials, title: "Socials" },
  { page: Page.Photos, title: "Photos" },
];
