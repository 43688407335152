import { useState, useCallback } from 'react'

export const useToggle = (initialState: boolean = false) => {
  const [state, setState] = useState(initialState)

  return {
    state,
    toggle: useCallback(() => setState(state => !state), []),
    on: useCallback(() => setState(true), []),
    off: useCallback(() => setState(false), []),
  }
}
