import React from "react";
import styles from "./OutNow.module.scss";
import WarCry from "../../../images/warcry.jpg";

export const OutNow: React.FC = () => (
  <a
    href="https://streetpieces.bandcamp.com/track/one-for-the-road"
    target="_blank"
    rel="noreferrer"
  >
    <img
      alt="One For The Road album art"
      src={WarCry}
      className={styles.image}
    />
  </a>
);
